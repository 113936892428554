import React from 'react';
import { Form, FieldArray } from 'formik';
import { Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import DropzoneArea from '../../common/DropzoneArea';
import { useGetStakeholdersByTypeByTypeQuery } from '../../../redux/vmsApi';

const QuotationForm: React.FC<{ initialValues: any, handleSubmit: (values: any) => void, setFieldValue: any, values: any, showQuotationForm: boolean, setShowQuotationForm: React.Dispatch<React.SetStateAction<boolean>> }> = ({ initialValues, handleSubmit, setFieldValue, values, showQuotationForm, setShowQuotationForm }) => {

  const { data: stakeholders } = useGetStakeholdersByTypeByTypeQuery({ type: "Supplier" });
  const handleApproval = async (isApproved: boolean) => {
    if (!values.comment && !showQuotationForm) {
      toast.error("Please provide a comment before proceeding.", {
        position: toast.POSITION.TOP_CENTER,
        theme: 'colored',
      });
      return;
    } else if (showQuotationForm && (values.quotations[0]?.attachments?.length === 0 || values.quotations.length === 0)) {
      toast.error("Please provide at least one quotation.", {
        position: toast.POSITION.TOP_CENTER,
        theme: 'colored',
      });
      return;
    } else {
      console.log("showQuotationForm", showQuotationForm);
      console.log("values.quotations.length", values.quotations[0]?.attachments?.length);
      values.approved = isApproved;
      console.log("Form values before submission: ", values);

      handleSubmit(values);
    }
  };




  const handleSupplierChange = (event: SelectChangeEvent<any>, index: number) => {
    const supplierId = event.target.value;
  
    const supplierObject = stakeholders?.find((supplier: any) => supplier.id === supplierId);
    const supplierName = supplierObject ? supplierObject.name : '';
    const paymentTerms = supplierObject ? supplierObject.paymentTerms : '';
  
    const updatedQuotations = [...values.quotations];
    updatedQuotations[index] = {
      ...updatedQuotations[index],
      supplier: { id: supplierId, name: supplierName },
      paymentTerms: paymentTerms,
    };
  
    setFieldValue('quotations', updatedQuotations);
  };





  return (
    <Form>
      <ToastContainer />
      <Stack spacing={4} width="100%" p={10} pb={5}>

        {!showQuotationForm && (
          <>

            <TextField
              name="comment"
              label="Comments"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              onChange={(e) => setFieldValue("comment", e.target.value)}
            />


            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <ConfirmationDialog
                  actionType="Approve"

                  onConfirm={() => handleApproval(true)}
                />
                <ConfirmationDialog
                  actionType="Reject"

                  onConfirm={() => handleApproval(false)}
                />
              </Stack>
            </Stack>
          </>
        )}


        {showQuotationForm && (
          <>
            <FieldArray name="quotations">
              {({ push, remove }) => (
                <Stack spacing={3}>
                  {values.quotations?.map((quotation: any, index: any) => (
                    <Card
                      key={index}
                      sx={{
                        margin: 2,
                        padding: 2,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 4,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #ccc',
                        width: '100%',
                      }}
                    >
                      <CardHeader
                        title={
                          <Typography variant="h6" component="div" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                            Supplier Details
                          </Typography>
                        }
                        action={
                          <IconButton onClick={() => remove(index)} color="error">
                            <RemoveCircleOutline />
                          </IconButton>
                        }
                        sx={{ paddingBottom: 0 }}
                      />
                      <Divider sx={{ marginY: 2 }} />
                      <CardContent>
                        <Grid container spacing={2} alignItems="flex-start">
                          <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                              Supplier
                            </Typography>
                            <Select
                              name={`quotations.${index}.supplier`}
                              fullWidth
                              value={quotation.supplier.id}
                              onChange={(event) => handleSupplierChange(event, index)}
                              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                            >
                              {stakeholders?.map((stakeholder: any) => (
                                <MenuItem key={stakeholder.id} value={stakeholder.id}>
                                  {stakeholder.name}
                                </MenuItem>
                              ))}
                            </Select>

                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                              Payment Terms
                            </Typography>
                            <TextField
                              name={`quotations.${index}.paymentTerms`}
                              fullWidth
                              variant="outlined"
                              value={quotation.paymentTerms}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                            />

                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                              Remarks
                            </Typography>
                            <TextField
                              name={`quotations.${index}.remarks`}
                              fullWidth
                              variant="outlined"
                              value={quotation.remarks}
                              onChange={(e) => setFieldValue(`quotations.${index}.remarks`, e.target.value)}
                              placeholder="Enter remarks"
                              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                              Attachments
                            </Typography>
                            <DropzoneArea
                              setFieldValue={setFieldValue}
                              fieldName={`quotations.${index}.attachments`}
                              attachments={quotation.attachments || []}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))}
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleOutline />}
                    onClick={() =>
                      push({
                        supplier: '',
                        paymentTerms: '',
                        remarks: '',
                        attachments: [],
                      })
                    }
                    sx={{
                      color: '#1976d2',
                      borderColor: '#1976d2',
                      '&:hover': {
                        backgroundColor: '#e3f2fd',
                        borderColor: '#1976d2',
                      },
                    }}
                  >
                    Add Quotation
                  </Button>
                </Stack>
              )}
            </FieldArray>


            <ConfirmationDialog
              actionType="Submit Info"

              onConfirm={() => handleApproval(true)}
            />
          </>
        )}
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setShowQuotationForm(false)}
        >
          Go back Dashboard
        </Button>
      </Stack>
    </Form>
  );
};

export default QuotationForm;
