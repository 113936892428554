import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import {
  ProcurementProcessWithRelations,
  useGetProcurementProcessesQuery,
  useGetUsersMeQuery,
} from '../../../redux/vmsApi';
const camelCaseToSpaced = (text: string) => text.replace(/([A-Z])/g, ' $1').trim();
const removeHyphens = (text: string) => text.replace(/-/g, ' ');

const ProcurementProcessList: React.FC = () => {
  const [currentTask, setCurrentTask] = useState('all');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data: user } = useGetUsersMeQuery();
  const { data: procurementProcesses, isLoading, isError } = useGetProcurementProcessesQuery({
    currentTask,
    skip: page * pageSize,
    limit: pageSize
  });

  const handleChangeTask = (event: SelectChangeEvent) => setCurrentTask(event.target.value as string);
  const handlePageChange = (newPage: number) => setPage(newPage);
  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    setPage(0);
  };

  const handleNavigate = (task: string, id: string) => {
    window.location.href = `/procurement/${task}/${id}`;
  };

  const isButtonDisabled = (currentTaskOwner: string | undefined) => {
    if (user?.roles?.includes('admin')) {
      return false;
    }
    return !user?.roles?.includes(currentTaskOwner || '');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching Procurement Processes.</div>;
  }

  return (
    <React.Fragment>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 150, marginBottom: 2 }}>
        <InputLabel id="current-task-label">Current Task</InputLabel>
        <Select
          labelId="current-task-select-label"
          id="current-task-select"
          value={currentTask}
          onChange={handleChangeTask}
          label="Current Task"
          sx={{ backgroundColor: '#f0f4f7' }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="SiteManagerReviewPurchaseRequisition">{camelCaseToSpaced('SiteManagerReviewPurchaseRequisition')}</MenuItem>
          <MenuItem value="CPOReviewPurchaseRequisition">{camelCaseToSpaced('CPOReviewPurchaseRequisition')}</MenuItem>
          <MenuItem value="SiteAgentUploadDocuments">{camelCaseToSpaced('SiteAgentUploadDocuments')}</MenuItem>
          <MenuItem value="PrintRequisition">{camelCaseToSpaced('PrintRequisition')}</MenuItem>
          <MenuItem value="VendorSelection">{camelCaseToSpaced('VendorSelection')}</MenuItem>
          <MenuItem value="ProcurementApplication">{camelCaseToSpaced('ProcurementApplication')}</MenuItem>
        </Select>
      </FormControl>

      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#1976d2' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Number</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Title</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Requisition Raised By</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Date Raised</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Current Task</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Current Task Owner</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Previous Task</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Development</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }} align="right">Project</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procurementProcesses && procurementProcesses.length > 0 ? (
              procurementProcesses.map((procurementProcess: ProcurementProcessWithRelations, index: number) => (
                <TableRow key={procurementProcess.id} sx={{ backgroundColor: index % 2 === 0 ? '#f0f4f7' : 'white' }}>
                  <TableCell component="th" scope="row">
                    {procurementProcess.requisition?.reqNumber || 'N/A'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {procurementProcess.requisition?.title || 'N/A'}
                  </TableCell>

                  <TableCell align="right">
                    {procurementProcess.initiatedByUser?.name || 'N/A'}
                  </TableCell>
                  <TableCell align="right">
                    {procurementProcess.initiatedDate ? new Date(procurementProcess.initiatedDate).toLocaleDateString() : 'N/A'}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => handleNavigate(`${procurementProcess.currentTask}`, `${procurementProcess.id}`)}
                      disabled={isButtonDisabled(procurementProcess.currentTaskOwner)}
                      sx={{
                        backgroundColor: '#F7C914',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#1976D2',
                        },
                      }}
                      startIcon={isButtonDisabled(procurementProcess.currentTaskOwner) ? null : <Box component="span" className="material-icons">arrow_forward</Box>}
                    >
                      {camelCaseToSpaced(procurementProcess.currentTask || 'N/A')}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    {procurementProcess.currentTaskOwner ? removeHyphens(procurementProcess.currentTaskOwner) : 'N/A'}
                  </TableCell>
                  <TableCell align="right">
                    {camelCaseToSpaced(procurementProcess.previousTask || 'N/A')}
                  </TableCell>
                  <TableCell align="right">
                    {procurementProcess.requisition?.development?.name || 'N/A'}
                  </TableCell>
                  <TableCell align="right">
                    {procurementProcess.requisition?.project?.name || 'N/A'}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">No matching Procurement Processes found.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>


      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Button
          onClick={() => handlePageChange(Math.max(page - 1, 0))}
          disabled={page === 0}
          variant="contained"
          sx={{ marginRight: 2 }}
        >
          Previous
        </Button>

        <Typography variant="body1">Page {page + 1}</Typography>

        <Button
          onClick={() => handlePageChange(page + 1)}
          disabled={procurementProcesses?.length === undefined || procurementProcesses.length < pageSize}
          variant="contained"
          sx={{ marginLeft: 2 }}
        >
          Next
        </Button>
      </Box>


      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 2 }}>Rows per page:</Typography>
        <FormControl variant="outlined" size="small">
          <Select
            value={pageSize}
            onChange={handlePageSizeChange}
            sx={{ backgroundColor: '#f0f4f7' }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </React.Fragment>
  );
};

export default ProcurementProcessList;
